import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useIntl } from 'react-intl';
import { getAffiliateEarnings } from '../../actions/ticketAction';
import { formatDate } from '../../utils/timeFormater';

const PaymentRecordsTable = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const loginUser = useSelector(state => state.loginIn.user);
    // const paymentRecords = useSelector(state => state.affiliate.paymentRecords); // Assume this data is available in the state
    const [paymentRecords, setPaymentRecords] = React.useState([]);


    React.useEffect(() => {
        dispatch(getAffiliateEarnings({}, (payments) => {
            if (payments) {
                setPaymentRecords(payments);
            }
        }))
    }, [])

    return (<>
        <div style={{ marginTop: 20, fontWeight: 'bold', fontSize: '16px' }}>
            Your commission rate
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px' }}>
            <span style={{ marginRight: '10px', color: '#555' }}>{loginUser?.commission}% of your referred user payment</span>
        </div>

        <div style={{ marginTop: 20, paddingBottom: 10, fontWeight: 'bold', fontSize: '16px' }}>
            Referred user payments
        </div>
        <div style={{ marginTop: 0, paddingBottom: 10, fontSize: '15px' }}>
            Total commission: ${paymentRecords.reduce((total, payment) => total + (payment.amount / 100 * (loginUser.commission / 100)), 0).toFixed(2)}
        </div>
        <div style={{ border: '1px solid #ccc', borderRadius: '4px', overflow: 'hidden', marginRight: 20 }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', padding: '10px', backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                <span style={{ color: '#333' }}>Username</span>
                <span style={{ color: '#333' }}>Amount</span>
                <span style={{ color: '#333' }}>Commission</span>
                <span style={{ color: '#333' }}>Date</span>
            </div>
            {paymentRecords.map((payment) => (
                <div key={payment.id} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', padding: '10px', borderBottom: '1px solid #eee', fontSize: 13 }}>
                    <span style={{ color: '#333' }}>{payment.username.substring(0, 3) + '***'}</span>
                    <span style={{ color: '#333' }}>{payment.amount / 100}</span>
                    <span style={{ color: '#333' }}>{payment.amount / 100 * (loginUser.commission / 100)}</span>
                    <span style={{ color: '#333' }}>{formatDate(payment.createdAt)}</span>
                </div>
            ))}
        </div>
    </>
    );
}

export default PaymentRecordsTable; 