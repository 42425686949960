/**
 * 对用户名进行隐私保护处理
 * @param {string} username - 需要处理的用户名
 * @returns {string} - 经过隐私保护处理的用户名
 */
export const maskUsername = (username) => {
  if (!username) return '';
  
  // 检查是否为邮件地址
  const isEmail = username.includes('@');
  
  if (isEmail) {
    const [localPart, domain] = username.split('@');
    
    // 只显示本地部分的前三个字符
    const visiblePart = localPart.slice(0, 3);
    const hiddenPart = '*'.repeat(Math.max(0, localPart.length - 3));
    
    return `${visiblePart}${hiddenPart}@${domain}`;
  } else {
    // 非邮件地址：显示前三个和后四个字符
    if (username.length <= 7) {
      return username; // 如果用户名长度小于等于7，直接返回原值
    }
    
    const prefix = username.slice(0, 3);
    const suffix = username.slice(-4);
    const hiddenLength = username.length - 7;
    const hiddenPart = '*'.repeat(hiddenLength);
    
    return `${prefix}${hiddenPart}${suffix}`;
  }
}; 