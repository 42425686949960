import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AI_API_MODEL, AI_USE_SEARCH, LLM_API_KEYS, LLM_API_KEY_MODAL } from "../../constants/actionTypes";
import { useDispatch, useSelector } from 'react-redux';
import { Selector } from '../common/Selector';
import { EditableMenuItemActions } from './EditableMenuItemActions';
import { useIntl } from 'react-intl';
import { Globe } from '@styled-icons/boxicons-regular/Globe'
import { Tooltip } from '@mui/material';

const ModelSelector = ({ inputStyle, dropdownIconSize, onSelect, value, show_search_web_button = true }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const appConfigs = useSelector(state => state.uiState.app_config);
    const llm_api_keys = useSelector(state => state.uiState.llm_api_keys);
    const selected_ai_api_model = useSelector(state => state.uiState.ai_api_model);
    const ai_use_search = useSelector(state => state.uiState.ai_use_search);
    const [ai_api_model, set_ai_api_model] = useState();
    const [models, set_models] = useState();

    useEffect(() => {
        let model = !!selected_ai_api_model && (llm_api_keys?.find(item => item.id === selected_ai_api_model) || appConfigs?.ai_api_models?.find(item => item.value === selected_ai_api_model))

        if (!model) {
            dispatch({
                type: AI_API_MODEL,
                value: appConfigs?.ai_api_models?.find(item => item.level == 't2')?.value
            })
        } else {
            set_ai_api_model(selected_ai_api_model)
        }
    }, [appConfigs?.ai_api_models, selected_ai_api_model, llm_api_keys])

    useEffect(() => {
        if (!appConfigs?.ai_api_models) return;

        let ai_models = [...appConfigs.ai_api_models];

        ai_models.push({
            type: 'title',
            label: intl.formatMessage({ id: 'self_owned_ai_api' })
        })

        if (llm_api_keys) {
            ai_models = ai_models.concat(llm_api_keys.map(item => ({
                label: item.name,
                value: item.id,
                rightElement: <EditableMenuItemActions
                    item={item}
                    onDelete={(item) => {
                        let keys = [...llm_api_keys];

                        dispatch({
                            type: LLM_API_KEYS,
                            value: keys.filter(it => it.id != item.id)
                        })
                    }}
                    onEdit={(item) => {
                        dispatch({
                            type: LLM_API_KEY_MODAL,
                            value: {
                                visible: true,
                                item
                            }
                        })
                    }}
                />
            })));
        }

        ai_models = ai_models.concat({
            label: `+ ${intl.formatMessage({ id: 'add_private_llm_key' })}`,
            onClick: () => {
                dispatch({
                    type: LLM_API_KEY_MODAL,
                    value: {
                        visible: true
                    }
                })
            }
        }).map(model => ({
            ...model,
            tips: model.desc,
        }));

        set_models(ai_models);
    }, [appConfigs?.ai_api_models, llm_api_keys, intl])

    const selectedModel = useMemo(() => {
        return models?.find(model => model.value === (value || ai_api_model));
    }, [ai_api_model, models, value])

    return <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        background: 'white',
        ...inputStyle
    }}>
        {
            selectedModel?.capabilities?.includes('search') && show_search_web_button &&
            <Tooltip title={intl.formatMessage({ id: ai_use_search? 'disable_ai_use_search' : 'enable_ai_use_search' })} placement="bottom">
                <div
                    className='hoverStand'
                    style={{
                        padding: 3,
                        paddingTop: 6,
                        paddingBottom: 6,
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        color: ai_use_search ? 'dodgerblue' : 'gray'
                    }}
                    onClick={() => {
                        dispatch({
                            type: AI_USE_SEARCH,
                            value: !ai_use_search
                        })
                    }}
                >
                    <Globe size={20} />
                </div>
            </Tooltip>
        }
        <Selector
            options={models}
            value={!!onSelect && value || ai_api_model}
            tooltip={intl.formatMessage({ id: 'set_ai_model' })}
            onChange={(value) => {
                if (!value) return;

                !!onSelect && onSelect(value);

                !onSelect && dispatch({
                    type: AI_API_MODEL,
                    value
                });
            }}
            // inputStyle={inputStyle}
            inputStyle={{
                border: '0px',
                padding: 6,
            }}
            itemStyle={{
                paddingLeft: 12,
                minWidth: 240
            }}
            dropdownIconSize={dropdownIconSize}
        />
    </div>
}

export default ModelSelector;