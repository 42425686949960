import { Tooltip } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Close } from '@styled-icons/material';
import { node_color_themes } from './ColorMenu';
import { FlowCascade } from '@styled-icons/entypo/FlowCascade';
import { Edit } from "@styled-icons/fluentui-system-regular/Edit";
import { FLOW_INPUT_MODAL } from '@/constants/actionTypes';
import { useDispatch } from 'react-redux';
import ReactGA from "react-ga4";

const ActionItem = ({ index, parentIndex, nodeId, listType, text, onClick, color_theme, deleteEnabled, onDeleteItem, expandEnabled, onExpand }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [hovered, setHovered] = React.useState();
  const isSubItems = parentIndex != undefined;

  const itemRef = React.useRef();

  return <div
    ref={itemRef}
    onClick={onClick}
    style={{
      border: `1px solid ${color_theme.border}`,
      backgroundColor: color_theme.content_bg,
      boxShadow: `5px 5px 0px rgba(0, 0, 0, 10%)`,
      borderRadius: 4,
      padding: '6px',
      paddingTop: 2,
      paddingBottom: 3,
      paddingLeft: 4,
      cursor: onClick ? 'pointer' : undefined,
      fontSize: 13,
      display: 'flow',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'relative',
    }}

    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
  >
    <span style={{
      fontWeight: 500,
      fontSize: 16
    }}>{onClick ? (isSubItems ? '-' : '+') : ''} </span> {text}
    {hovered &&
      expandEnabled &&
      <Tooltip
        title={intl.formatMessage({ id: 'expand_ideas' })}
        placement='bottom'
      >
        <div
          className='circle-button'
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 20,
            width: 20,
            top: '50%',
            transform: 'translate(50%, -50%)',
            right: 0,
            borderColor: color_theme.border,
            backgroundColor: color_theme.content_bg
          }}
          onClick={(event) => {
            event.stopPropagation();
            onExpand(itemRef?.current);
          }}
        >
          <FlowCascade size={15} color='dodgerblue' />
        </div>
      </Tooltip>}
    {hovered &&
      <div
        style={{
          position: 'absolute',
          // transform: 'translateY(-50%)',
          // top: '50%',
          bottom: 4,
          right: expandEnabled ? 12 : 3,
          display: 'flex',
          flexDirection: 'row',
          columnGap: 3
        }}
      >
        <div
          className='transparent-background'
          style={{
            width: 18,
            height: 18,
            cursor: 'pointer'
          }}
          onClick={(event) => {
            event.stopPropagation();
            dispatch({
              type: FLOW_INPUT_MODAL,
              value: {
                id: 'item_text',
                index,
                parentIndex,
                listType,
                value: text,
                nodeId,
                multiline: true,
                visible: true
              }
            })
          }}
        >
          <Edit color='gray' size={16} />
        </div>
        {
          deleteEnabled &&
          <Tooltip
            title={intl.formatMessage({ id: 'delete' })}
            placement='top'
          >
            <div
              className='transparent-background'
              style={{
                width: 18,
                height: 18,
                cursor: 'pointer'
              }}
              onClick={(event) => {
                event.stopPropagation();
                onDeleteItem(index, parentIndex);
              }}
            >
              <Close color='gray' size={16} />
            </div>
          </Tooltip>
        }
      </div>
    }
  </div>;
};

export const AIActionList = ({ nodeId, listType, list, parentIndex, action, queryType, nodeType, aiItemClicked, onExpand, onDeleteItem, expandEnabled, ai_generated, color_start = 0, color_theme }) => {
  const isSubItems = parentIndex != undefined;
  return <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      paddingRight: isSubItems ? undefined : 10,
      paddingLeft: !isSubItems ? undefined : 16,
      marginTop: isSubItems ? undefined : 5,
      marginBottom: isSubItems ? undefined : 5,
      // paddingRight: 11,
      // marginTop: 5,
      // marginBottom: 5,
    }}
  > {list?.map((item, index) => {
    if (!item) return;

    let text = item;
    let subItems;

    if (typeof text === 'object') {
      text = item.name;
      subItems = item.branches;
    }

    let colorTheme = color_theme || node_color_themes[(index + color_start) % node_color_themes.length];

    text = text?.trim();
    if(!text) return;

    let itemEle = <ActionItem
      key={index + ''}
      index={index}
      parentIndex={parentIndex}
      nodeId={nodeId}
      listType={listType}
      text={text}
      color_theme={colorTheme}
      expandEnabled={expandEnabled}
      onExpand={!onExpand ? undefined : (anchor) => {
        ReactGA.event({ category: 'ActionListItem', action: 'expand ideas', label: listType });

        // aiItemClicked({ item: { action: 'breakdown' }, nodeType, queryType: 'breakdown', userInput: text, color_theme });
        onExpand(anchor, text)
      }}
      onClick={!aiItemClicked ? undefined : () => {
        ReactGA.event({ category: 'ActionListItem', action: action || 'query', label: listType });

        aiItemClicked({ item: { action: action || 'query' }, nodeType, queryType, userInput: text, color_theme: colorTheme, ai_generated });
      }}
      deleteEnabled={!!onDeleteItem}
      onDeleteItem={onDeleteItem} />;

    if (subItems) {
      itemEle = <>
        {itemEle}
        <AIActionList
          key={index + '_sub'}
          list={subItems}
          nodeId={nodeId}
          listType={listType}
          action={action}
          queryType={queryType}
          aiItemClicked={aiItemClicked}
          onExpand={onExpand}
          onDeleteItem={onDeleteItem}
          expandEnabled={expandEnabled}
          ai_generated={ai_generated}
          parentIndex={index}
          color_theme={colorTheme}
        />
      </>;
    }

    return itemEle;
  })}
  </div>;
};
