export const isYoutube = (link) => {
    return link?.toLowerCase().includes('youtube.com/') || link?.toLowerCase().includes('youtu.be/')
}

function compareTracks(track1, track2) {
    const langCode1 = track1.languageCode
    const langCode2 = track2.languageCode

    if (langCode1 === 'en' && langCode2 !== 'en') {
        return -1 // English comes first
    } else if (langCode1 !== 'en' && langCode2 === 'en') {
        return 1 // English comes first
    } else if (track1.kind !== 'asr' && track2.kind === 'asr') {
        return -1 // Non-ASR comes first
    } else if (track1.kind === 'asr' && track2.kind !== 'asr') {
        return 1 // Non-ASR comes first
    }

    return 0 // Preserve order if both have same priority
}

export const retrieveYoutubeInfo = async (videoId) => {
    const YT_INITIAL_PLAYER_RESPONSE_RE =
        /ytInitialPlayerResponse\s*=\s*({.+?})\s*;\s*(?:var\s+(?:meta|head)|<\/script|\n)/
    let player = window.ytInitialPlayerResponse

    if (!player || videoId !== player.videoDetails?.videoId) {
        const res = await fetch(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`);
        const data = await res.json();
        console.log(data);
        
        console.log('url........', 'https://www.youtube.com/watch?v=' + videoId)
        const response = await fetch('https://www.youtube.com/watch?v=' + videoId)
        console.log('response............', response)
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const body = await response.text()
        const playerResponse = body.match(YT_INITIAL_PLAYER_RESPONSE_RE)
        if (!playerResponse) {
            throw new Error('Unable to parse playerResponse')
        }

        player = JSON.parse(playerResponse[1])
        const metadata = {
            title: player.videoDetails.title,
            description: player.videoDetails.shortDescription, // Added description
            duration: player.videoDetails.lengthSeconds,
            author: player.videoDetails.author,
            views: player.videoDetails.viewCount,
        }

        const tracks =
            player.captions?.playerCaptionsTracklistRenderer?.captionTracks
        if (!tracks || tracks.length === 0) {
            throw new Error('No caption tracks found')
        }

        tracks.sort(compareTracks)

        const transcriptResponse = await fetch(tracks[0].baseUrl + '&fmt=json3', { mode: 'no-cors' })
        if (!transcriptResponse.ok) {
            throw new Error(`HTTP error! status: ${transcriptResponse.status}`)
        }
        const transcript = await transcriptResponse.json()
        const parsedTranscript = transcript.events
            .filter((x) => x.segs)
            .map((x) =>
                x.segs
                    .map((y) => y.utf8)
                    .join(' ')
                    .replace(/[\u200B-\u200D\uFEFF]/g, '')
                    .replace(/\s+/g, ' ')
            )

        return { transcript: parsedTranscript, metadata } // Return both transcript and metadata
    } else {
        throw new Error('Player data not available')
    }
}