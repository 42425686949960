import { Tooltip } from "@mui/material";
import { useIntl } from "react-intl";

export const SearchGroundings = ({ data }) => {
  const intl = useIntl();
  const chips = data.webSearchQueries;
  return (
    <div className="flex items-start gap-2 p-2 mb-2 bg-gray-50 dark:bg-gray-800 rounded-lg">
      {/* Google Logo */}
      <Tooltip title={intl.formatMessage({ id: 'search_grounding' })} placement='bottom'>
        <div className="w-5 h-5" style={{ paddingTop: 7 }}>
          <svg class="logo-light" width="18" height="18" viewBox="9 9 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M42.8622 27.0064C42.8622 25.7839 42.7525 24.6084 42.5487 23.4799H26.3109V30.1568H35.5897C35.1821 32.3041 33.9596 34.1222 32.1258 35.3448V39.6864H37.7213C40.9814 36.677 42.8622 32.2571 42.8622 27.0064V27.0064Z" fill="#4285F4"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3109 43.8555C30.9659 43.8555 34.8687 42.3195 37.7213 39.6863L32.1258 35.3447C30.5898 36.3792 28.6306 37.0061 26.3109 37.0061C21.8282 37.0061 18.0195 33.9811 16.6559 29.906H10.9194V34.3573C13.7563 39.9841 19.5712 43.8555 26.3109 43.8555V43.8555Z" fill="#34A853"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6559 29.8904C16.3111 28.8559 16.1074 27.7588 16.1074 26.6146C16.1074 25.4704 16.3111 24.3733 16.6559 23.3388V18.8875H10.9194C9.74388 21.2072 9.06992 23.8247 9.06992 26.6146C9.06992 29.4045 9.74388 32.022 10.9194 34.3417L15.3864 30.8621L16.6559 29.8904V29.8904Z" fill="#FBBC05"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3109 16.2386C28.85 16.2386 31.107 17.1164 32.9095 18.8091L37.8466 13.8719C34.853 11.082 30.9659 9.3736 26.3109 9.3736C19.5712 9.3736 13.7563 13.245 10.9194 18.8875L16.6559 23.3388C18.0195 19.2636 21.8282 16.2386 26.3109 16.2386V16.2386Z" fill="#EA4335"></path>
          </svg>
        </div>
      </Tooltip>

      {/* Search Chips */}
      <div className="flex gap-2 flex-wrap">
        {chips.map((chip, index) => (
          <a
            key={index}
            href={`https://www.google.com/search?q=${chip.replace(' ', '+')}&client=app-vertex-grounding&safesearch=active`}
            target="_blank"
            rel="noopener noreferrer"
            className="px-4 py-1 bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-full text-sm hover:bg-gray-50 dark:hover:bg-gray-600 transition-colors"
            style={{
              maxWidth: 300,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {chip}
          </a>
        ))}
      </div>
    </div>
  );
};