import { RIL_FOLDER, SLIDES_FOLDER } from 'src/constants/constants';
import * as constants from './../constants/actionTypes';
import { cloneDeep } from 'lodash';

const init_state = {
    sidebarShow: true,
    docPath: [],
    docRefs: new Map(),
    appList: '',
    showAppList: false,
    rilTitleDialog: { visible: false, item: {} },
    noteDialog: { visible: false, item: {} },
    rilDialog: { visible: false, url: null },
    inviteToDocDialog: { visible: false },
    inviteToOrgDialog: { visible: false },
    joinWorkspaceDialog: { visible: false },
    settingsDialog: { visible: false },
    docHistoryDialog: { visible: false },
    dataEditorDialog: { visible: false },
    dbEditorDialog: { visible: false },
    editorDialog: { visible: false },
    addGroupDialog: { visible: false },
    addUserToGroupDialog: { visible: false, group: {} },
    confirmDialog: { visible: false, confirmHandler: null },
    refreshRils: 0,
    rilsFolder: RIL_FOLDER.ril,
    refreshSlides: 0,
    slidesFolder: SLIDES_FOLDER.normal,
    list_item_selected: null,
    lng: 'en',
    toHtml: {
        hid: '',
    },
    aiDialog: {
    },
    available_products: {},
    serving_products: [],
    flow_settings: {
    }
};
export function uiReducer(state = init_state, action) {
    switch (action.type) {
        case constants.SIDE_BAR_SHOW:
            return Object.assign({}, state, {
                sidebarShow: action.value
            });
        case constants.DOC_PATH_ADD:
            return Object.assign({}, state, {
                docPath: [action.value, ...state.docPath]
            });
        case constants.DOC_PATH_CLEAR:
            return Object.assign({}, state, {
                docPath: []
            });
        case constants.DOC_REFS:
            return Object.assign({}, state, {
                docRefs: action.value
            });
        case constants.APP_LIST:
            return Object.assign({}, state, {
                appList: action.value,
                showAppList: true,
                // sidebarShow: false
            });
        case constants.SHOW_APP_LIST:
            return Object.assign({}, state, {
                showAppList: action.value
            });
        case constants.JOIN_WORKSPACE_DIALOG:
            return Object.assign({}, state, {
                joinWorkspaceDialog: action.value
            });
        case constants.SETTINGS_DIALOG:
            return Object.assign({}, state, {
                settingsDialog: action.value
            });
        case constants.RIL_TITLE_DIALOG:
            return Object.assign({}, state, {
                rilTitleDialog: action.value
            });
        case constants.NOTE_DIALOG:
            return Object.assign({}, state, {
                noteDialog: action.value
            });
        case constants.RIL_DIALOG:
            return Object.assign({}, state, {
                rilDialog: action.value
            });
        case constants.ADD_GROUP_DIALOG:
            return Object.assign({}, state, {
                addGroupDialog: action.value
            });
        case constants.ADD_USER_TO_GROUP_DIALOG:
            return Object.assign({}, state, {
                addUserToGroupDialog: action.value
            });
        case constants.INVITE_DOC_DIALOG:
            return Object.assign({}, state, {
                inviteToDocDialog: action.value
            })
        case constants.INVITE_ORG_DIALOG:
            return Object.assign({}, state, {
                inviteToOrgDialog: action.value
            })
        case constants.CONFIRM_DIALOG:
            return Object.assign({}, state, {
                confirmDialog: action.value
            })
        case constants.ALERT_DIALOG:
            return Object.assign({}, state, {
                alertDialog: action.value
            })
        case constants.EXPORT_DOC_DIALOG:
            return Object.assign({}, state, {
                exportDocDialog: action.value
            })
        case constants.IMPORT_DOC_DIALOG:
            return Object.assign({}, state, {
                importDocDialog: action.value
            })
        case constants.DOC_HISTORY_DIALOG:
            return Object.assign({}, state, {
                docHistoryDialog: action.value
            })
        case constants.DATA_EDITOR_DIALOG:
            return Object.assign({}, state, {
                dataEditorDialog: action.value
            })
        case constants.DB_EDITOR_DIALOG:
            return Object.assign({}, state, {
                dbEditorDialog: action.value
            })
        case constants.EDITOR_DIALOG:
            return Object.assign({}, state, {
                editorDialog: action.value
            })
        case constants.FEEDBACKS_DIALOG:
            return Object.assign({}, state, {
                feedbacksDialog: action.value
            })
        case constants.INVITE_FRIENDS_DIALOG:
            return Object.assign({}, state, {
                inviteFriendsDialog: action.value
            })
        case constants.APP_UPGRADE_DIALOG:
            return Object.assign({}, state, {
                appUpgradeDialog: action.value
            })
        case constants.EMOJI_PICKER_DIALOG:
            return Object.assign({}, state, {
                emojiPickerDialog: action.value
            });
        case constants.AI_ASSISTANT_DIALOG:
            return Object.assign({}, state, {
                aiDialog: action.value
            })
        case constants.IMAGE_UPLOAD_DIALOG:
            return Object.assign({}, state, {
                imageUploadDialog: action.value
            })
        case constants.POLL_GENERATOR_DIALOG:
            return Object.assign({}, state, {
                pollGeneratorDialog: action.value
            })
        case constants.CHART_GENERATOR_DIALOG:
            return Object.assign({}, state, {
                chartGeneratorDialog: action.value
            })
        case constants.LINK_INPUT_DIALOG:
            return Object.assign({}, state, {
                linkInputDialog: action.value
            })
        case constants.TABLE_BUILDER_DIALOG:
            return Object.assign({}, state, {
                tableBuilderDialog: action.value
            })
        case constants.PROMPT_DIALOG:
            return Object.assign({}, state, {
                promptDialog: action.value
            })
        case constants.PROMPTS_DIALOG:
            return Object.assign({}, state, {
                promptsDialog: action.value
            })
        case constants.STRIPE_CHECKOUT_DIALOG:
            return Object.assign({}, state, {
                stripeCheckoutDialog: action.value
            });
        case constants.BILL_PERIOD_DIALOG:
            return Object.assign({}, state, {
                billPeriodDialog: action.value
            });

        case constants.REFRESH_RILS:
            return Object.assign({}, state, {
                refreshRils: state.refreshRils + 1
            })
        case constants.RILS_FOLDER:
            return Object.assign({}, state, {
                rilsFolder: action.value
            })
        case constants.REFRESH_SLIDES:
            return Object.assign({}, state, {
                refreshSlides: state.refreshSlides + 1
            })
        case constants.SLIDES_FOLDER:
            return Object.assign({}, state, {
                slidesFolder: action.value
            })
        case constants.LIST_ITEM_SELECTED:
            return Object.assign({}, state, {
                list_item_selected: action.value
            })

        case constants.TODOS_ACTIONS.added:
            return Object.assign({}, state, {
                jumpToHeader: 'todo'
            })
        case constants.DOINGS_ACTIONS.added:
            return Object.assign({}, state, {
                jumpToHeader: 'doing'
            })
        case constants.RILS_ACTIONS.added:
            return Object.assign({}, state, {
                jumpToHeader: 'ril'
            })
        case constants.LIST_JUMPED:
            return Object.assign({}, state, {
                jumpToHeader: null
            })
        case constants.TICKET_DETAIL.comment_form_height:
            return Object.assign({}, state, {
                ticket_detail_page: { ...state.ticket_detail_page, comment_form_height: action.value }
            })
        case constants.TICKET_DETAIL.comments_header_height:
            return Object.assign({}, state, {
                ticket_detail_page: { ...state.ticket_detail_page, comments_header_height: action.value }
            })
        case constants.TICKET_IN_VIEW:
            return Object.assign({}, state, {
                ticket_in_view: action.value
            })
        case constants.SCREEN_SIZE:
            return Object.assign({}, state, {
                screen_size: action.value
            });
        case constants.SHOWN_CLIPBOARD_URL:
            return Object.assign({}, state, {
                shown_clipboard_urls: [...(Array.isArray(state.shown_clipboard_urls) ? state.shown_clipboard_urls : []), action.value]
            });
        case constants.NET_INFO:
            return Object.assign({}, state, {
                net_info: action.value
            })
        case constants.AUDIO_FLOAT_CONTROLLER_OPEN:
            if (state.audio_controller && typeof state.audio_controller.destroy === 'function') {
                state.audio_controller.destroy();
            }
            return Object.assign({}, state, {
                audio_controller: action.value
            })
        case constants.AUDIO_FLOAT_CONTROLLER_CLOSE:
            if (state.audio_controller && typeof state.audio_controller.destroy === 'function') {
                state.audio_controller.destroy();
            }
            return Object.assign({}, state, {
                audio_controller: null
            })
        case constants.AUDIO_FLOAT_CONTROLLER_UI_VISIBLE:
            return Object.assign({}, state, {
                audio_controller_ui_visible: action.value
            });
        case constants.READING_ARTICLE:
            return Object.assign({}, state, {
                reading_article: action.value
            });
        case constants.READING_LIST:
            return Object.assign({}, state, {
                reading_list: action.value
            });
        case constants.AUDIO_READ_SPEED:
            return Object.assign({}, state, {
                audio_read_speed: action.value
            });
        case constants.AUDIO_READ_PITCH:
            return Object.assign({}, state, {
                audio_read_pitch: action.value
            });
        case constants.AUDIO_READ_VOICE:
            return Object.assign({}, state, {
                audio_read_voice: action.value
            });
        case constants.PROMOTE_VIP:
            return Object.assign({}, state, {
                promote_vip: action.value
            });
        case constants.REFRESH_EDITOR_CONTENT:
            return Object.assign({}, state, {
                contentRefresher: {
                    target: action.value,
                    stamp: Math.random(),
                }
            });

        case constants.REFRESH_DOC:
            return Object.assign({}, state, {
                docRefresher: {
                    target: action.value,
                    stamp: Math.random(),
                }
            });

        case constants.DB_CAPABILITY_ENABLED:
            return Object.assign({}, state, {
                db_enabled: action.value
            });


        case constants.TEXT_SELECTION:
            return Object.assign({}, state, {
                selection: action.value
            });
        case constants.TITLE_SELECTION:
            return Object.assign({}, state, {
                titleSelected: action.value
            });

        case constants.SLIDE_EDITOR_MESSAGE:
            return Object.assign({}, state, {
                slideEditorMessage: action.value
            });

        case constants.LNG_SETTING:
            return Object.assign({}, state, {
                lng: action.value
            });
        case constants.LNG_LIST:
            return Object.assign({}, state, {
                lng_list: action.item
            });

        case constants.TO_HTML:
            return Object.assign({}, state, {
                toHtml: action.value
            });

        case constants.CURRENT_EDITOR:
            return Object.assign({}, state, {
                currentEditor: action.value
            });

        case constants.APP_CONFIG:
            return Object.assign({}, state, {
                app_config: action.value
            });

        case constants.SERVING_PRODUCTS:
            return Object.assign({}, state, {
                serving_products: action.item
            });
        case constants.SERVING_PRODUCT:
            let curr_serving_products = cloneDeep(state.serving_products);
            let index = curr_serving_products.findIndex(sp => sp.service == action.item.service);
            if (index > -1) {
                curr_serving_products[index] = action.item;
            } else {
                curr_serving_products.push(action.item);
            }

            return Object.assign({}, state, {
                serving_products: curr_serving_products
            });
        case constants.AVAILABLE_PRODUCTS:
            return Object.assign({}, state, {
                available_products: action.item
            });
        case constants.AI_COINS_PRODUCTS:
            return Object.assign({}, state, {
                ai_coins_products: action.item
            });

        case constants.AI_SETTINGS:
            return Object.assign({}, state, {
                aiSettings: action.value
            });
        case constants.INVITED_CODE:
            return Object.assign({}, state, {
                invited_code: action.value
            })

        case constants.INVITED_RECORD:
            return Object.assign({}, state, {
                invited_record: action.value
            })

        case constants.UNSAVED_FLOW:
            return Object.assign({}, state, {
                unsaved_flow: action.value
            })
        case constants.FLOW_MODAL:
            return Object.assign({}, state, {
                flow_modal: action.value
            })
        case constants.FLOW_INPUT_MODAL:
            return Object.assign({}, state, {
                flow_input_modal: action.value
            })
        case constants.AI_TOKEN_BALANCE_TRANS:
            return Object.assign({}, state, {
                ai_token_balance_trans: action.item,
            })
        case constants.FLOW_EXPORT_IMAGE_WITHOUT_QRCODE:
            return Object.assign({}, state, {
                flow_export_image_without_qrcode: action.value,
            })
        case constants.AI_API_MODEL:
            return Object.assign({}, state, {
                ai_api_model: action.value,
            })
        case constants.AI_USE_SEARCH:
            return Object.assign({}, state, {
                ai_use_search: action.value,
            })
        case constants.LLM_API_KEY_MODAL:
            return Object.assign({}, state, {
                llmApiKeyDialog: action.value
            })
        case constants.LLM_API_KEYS:
            return Object.assign({}, state, {
                llm_api_keys: action.value
            })
        case constants.FLOW_SETTINGS:
            return Object.assign({}, state, {
                flow_settings: action.value
            })
        case constants.USER_GUIDES:
            return Object.assign({}, state, {
                user_guides: action.value
            })
        case constants.ADD_FLOW_NODE:
            return Object.assign({}, state, {
                flow_node: action.value
            })
        case constants.PAGE_CHOOSER_DIALOG:
            return Object.assign({}, state, {
                page_chooser_dialog: action.value
            })


        default:
            return state;
    }
}