import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import { toPng } from 'html-to-image';
import { useIntl } from 'react-intl';
import { Close, Download, ZoomIn, ZoomOut, Fullscreen } from '@styled-icons/material';
import MermaidRenderer from '../common/MermaidRenderer';
import { preprocessSvg } from '../../utils/svgUtils';
import { Edit } from '@styled-icons/remix-line';
// import { useViewport } from '@xyflow/react';
const Artifact = ({ inDoc, selectedArtifact, onEdit, aigc_hovered, setSelectedArtifact, offsetTop, color_theme, zoom = 1 }) => {
    const intl = useIntl();
    const iframeRef = useRef(null);
    const containerRef = useRef(null);
    const [iframeSize, setIframeSize] = useState({ width: 320, height: 240 });
    const [isResizing, setIsResizing] = useState(false);
    const [resized, setResized] = useState(false);
    const [resizeDirection, setResizeDirection] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(null);
    const [scale, setScale] = useState(1);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [hovered, setHovered] = useState();
    // const { zoom } = useViewport();

    // useEffect(() => {
    //     if (iframeSize.width && iframeSize.height) {
    //         setAspectRatio(iframeSize.width / iframeSize.height);
    //     }
    // }, [iframeSize.width, iframeSize.height]);

    useEffect(() => {
        if (!containerRef?.current) return;
        const rect = containerRef.current.getBoundingClientRect();
        setAspectRatio(rect.width / rect.height)
    }, [containerRef?.current])

    const handleDownload = () => {
        const element = document.getElementById(selectedArtifact?.type === 'SVG' && `artifact-${selectedArtifact.id}` || selectedArtifact?.type === 'Mermaid' && `mermaid-chart-${selectedArtifact.id}` || 'artifact-content');
        toPng(element)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = 'artifact.png';
                link.href = dataUrl;
                link.click();
            })
            .catch((error) => {
                console.error('下载图片时出错:', error);
            });
    };

    useEffect(() => {
        const iframe = iframeRef?.current;
        if (isResizing || !iframe || selectedArtifact?.type !== 'HTML') return;

        const checkOverflow = () => {
            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            const bodyElement = iframeDocument.body;

            const isOverflowingHorizontally = bodyElement.scrollWidth > iframeRef.current?.clientWidth;
            const isOverflowingVertically = bodyElement.scrollHeight > iframeRef.current?.clientHeight;

            if (isOverflowingHorizontally) {
                setIframeSize(prev => ({ ...prev, width: bodyElement.scrollWidth + 24 }));
            }
            if (isOverflowingVertically) {
                setIframeSize(prev => ({ ...prev, height: bodyElement.scrollHeight + 24 * bodyElement.scrollHeight / bodyElement.scrollWidth }));
            }
        };

        const handleIframeLoad = () => {
            checkOverflow();
        };

        iframe.addEventListener('load', handleIframeLoad);

        // Set initial content
        let content = selectedArtifact?.content || '';
        if (!content.includes('<html')) {
            content = `<html><body>${content}</body></html>`;
        }
        iframe.srcdoc = content;

        return () => {
            iframe.removeEventListener('load', handleIframeLoad);
        };
    }, [isResizing, selectedArtifact]);

    const handleMouseDown = (e, direction) => {
        e.preventDefault();
        setIsResizing(true);
        setResizeDirection(direction);
    };

    const handleMouseMove = (e) => {
        if (!isResizing) return;

        setResized(true);

        const container = containerRef.current;
        const rect = container.getBoundingClientRect();

        if (resizeDirection === 'horizontal') {
            const iframeDocument = iframeRef?.current?.contentDocument || iframeRef?.current?.contentWindow?.document;
            const bodyElement = iframeDocument?.body;

            const newWidth = (e.clientX - rect.left) / zoom;
            // if (iframeDocument) {
            //     setIframeSize({ width: newWidth, height: bodyElement.scrollHeight + 24 * bodyElement.scrollHeight / bodyElement.scrollWidth });
            // } else {
            setIframeSize(prevState => ({ ...(prevState || {}), width: newWidth, height: rect.clientHeight }));
            // }
        } else if (resizeDirection === 'vertical') {
            const newHeight = (e.clientY - rect.top) / zoom;
            setIframeSize(prev => ({ ...prev, height: newHeight }));
        } else if (resizeDirection === 'corner') {
            const newWidth = (e.clientX - rect.left) / zoom;
            const newHeight = (e.clientY - rect.top) / zoom;

            if (aspectRatio) {
                if (newWidth / newHeight > aspectRatio) {
                    setIframeSize({ width: newHeight * aspectRatio, height: newHeight });
                } else {
                    setIframeSize({ width: newWidth, height: newWidth / aspectRatio });
                }
            } else {
                setIframeSize({ width: newWidth, height: newHeight });
            }
        }
    };

    const handleMouseUp = () => {
        setIsResizing(false);
        setResizeDirection(null);
    };

    useEffect(() => {
        if (isResizing) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing]);

    const [svgContent, setSvgContent] = useState(null);

    useEffect(() => {
        if (selectedArtifact?.type !== 'SVG') {
            return;
        }

        const preprocessedSvgString = preprocessSvg(selectedArtifact.content);
        setSvgContent(preprocessedSvgString);
        // setError(null);
    }, [selectedArtifact]);

    const handleZoomIn = () => {
        setScale(prev => Math.min(prev + 0.2, 5));
        setPosition({ x: 0, y: 0 });
    };

    const handleZoomOut = () => {
        setScale(prev => Math.max(prev - 0.2, 0.5));
        setPosition({ x: 0, y: 0 });
    };

    const handleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
        setPosition({ x: 0, y: 0 });
        const container = containerRef.current;

        if (!isFullscreen) {
            if (container.requestFullscreen) {
                container.requestFullscreen();
            } else if (container.webkitRequestFullscreen) {
                container.webkitRequestFullscreen();
            } else if (container.msRequestFullscreen) {
                container.msRequestFullscreen();
            }
            setScale(1); // 重置缩放
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            if (!document.fullscreenElement &&
                !document.webkitFullscreenElement &&
                !document.msFullscreenElement) {
                setIsFullscreen(false);
                setScale(1);
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('msfullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('msfullscreenchange', handleFullscreenChange);
        };
    }, []);

    const handleContainerMouseDown = (e) => {
        if (
            e.target.closest('.resize-handle') ||
            e.target.closest('.transparent-background')
        ) {
            return;
        }

        setIsDragging(true);
        setDragStart({
            x: e.clientX - position.x,
            y: e.clientY - position.y
        });
    };

    const handleContainerMouseMove = (e) => {
        if (!isDragging) return;

        const newX = e.clientX - dragStart.x;
        const newY = e.clientY - dragStart.y;

        setPosition({
            x: newX,
            y: newY
        });
    };

    const handleContainerMouseUp = () => {
        setIsDragging(false);
    };

    return (
        <div
            ref={containerRef}
            className='nodrag'
            onMouseDown={handleContainerMouseDown}
            onMouseMove={handleContainerMouseMove}
            onMouseUp={handleContainerMouseUp}
            // onMouseLeave={handleContainerMouseUp}
            style={{
                position: !inDoc ? 'absolute' : undefined,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                left: 'calc(100% + 20px)',
                top: offsetTop,
                minWidth: selectedArtifact?.type === 'Mermaid' ? 480 : 320,
                minHeight: 240,
                width: inDoc ? undefined : iframeSize?.width && resized ? `${iframeSize.width}px` : 'fit-content',
                height: iframeSize?.height && resized ? `${iframeSize.height}px` : undefined,
                border: `1px solid #ccc`,
                backgroundColor: color_theme?.content_bg || 'white',
                boxShadow: inDoc ? undefined : '0px 0px 8px #bbb',
                overflow: 'hidden',
                cursor: isDragging ? 'grabbing' : 'grab',
                ...(isFullscreen && {
                    width: '100%',
                    height: '100%',
                })
            }}

            onMouseEnter={inDoc ? () => {
                setHovered(true)
            } : undefined}

            onMouseLeave={inDoc ? () => {
                setHovered(false)
                handleContainerMouseUp()
            } : handleContainerMouseUp}
        >
            <div id="artifact-content"
                className='fill-available'
                style={{
                    position: 'relative',
                    height: '100%',
                    backgroundColor: color_theme?.content_bg || 'white',
                    transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
                    transformOrigin: 'center center',
                    transition: isDragging ? 'none' : 'transform 0.3s ease',
                    ...(isFullscreen && {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    })
                }}
            >
                {
                    selectedArtifact?.type === 'SVG' &&
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        id={`artifact-${selectedArtifact.id}`}
                        dangerouslySetInnerHTML={{ __html: svgContent }}
                    />
                }
                {
                    selectedArtifact?.type === 'HTML' &&
                    <iframe
                        ref={iframeRef}
                        style={{
                            width: `${iframeSize.width}px`,
                            height: `${iframeSize.height}px`,
                            border: 'none',
                            backgroundColor: 'white',
                        }}
                    />
                }
                {
                    selectedArtifact?.type === 'Mermaid' &&
                    <MermaidRenderer id={'mermaid-chart-' + selectedArtifact.id} source={selectedArtifact.content} />
                }
            </div>

            <div style={{
                display: (aigc_hovered || hovered) ? 'flex' : 'none',
                flexDirection: 'column',
                position: 'absolute',
                bottom: 10,
                right: 0,
                gap: '5px',
                padding: '5px',
            }}>
                <Tooltip title={intl.formatMessage({ id: 'zoom_in' })} placement='left'>
                    <div
                        className='transparent-background'
                        style={{
                            width: 28,
                            height: 28,
                            color: '#777',
                            cursor: 'pointer',
                        }}
                        onClick={handleZoomIn}
                    >
                        <ZoomIn size={24} />
                    </div>
                </Tooltip>

                <Tooltip title={intl.formatMessage({ id: 'zoom_out' })} placement='left'>
                    <div
                        className='transparent-background'
                        style={{
                            width: 28,
                            height: 28,
                            color: '#777',
                            cursor: 'pointer',
                        }}
                        onClick={handleZoomOut}
                    >
                        <ZoomOut size={24} />
                    </div>
                </Tooltip>

                <Tooltip title={intl.formatMessage({ id: 'fullscreen' })} placement='left'>
                    <div
                        className='transparent-background'
                        style={{
                            width: 28,
                            height: 28,
                            color: '#777',
                            cursor: 'pointer',
                        }}
                        onClick={handleFullscreen}
                    >
                        <Fullscreen size={24} />
                    </div>
                </Tooltip>
            </div>

            {(aigc_hovered || hovered) && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'absolute',
                    top: inDoc ? 8 : 0,
                    right: inDoc ? 4 : 0,
                    rowGap: 4
                }}>
                    {
                        inDoc &&
                        <Tooltip
                            title={intl.formatMessage({ id: 'edit' })}
                            placement='top'
                        >
                            <div
                                className='transparent-background'
                                style={{
                                    width: 25,
                                    height: 25,
                                    color: '#777',
                                    cursor: 'pointer',
                                    padding: 3,
                                }}
                                onClick={onEdit}
                            >
                                <Edit size={18} />
                            </div>
                        </Tooltip>
                    }


                    <Tooltip
                        title={intl.formatMessage({ id: 'export_to_image' })}
                        placement='top'
                    >
                        <div
                            className='transparent-background'
                            style={{
                                width: 25,
                                height: 25,
                                color: '#777',
                                cursor: 'pointer',
                            }}
                            onClick={handleDownload}
                        >
                            <Download size={20} />
                        </div>
                    </Tooltip>

                    {
                        !!setSelectedArtifact &&
                        <Tooltip
                            title={intl.formatMessage({ id: 'close' })}
                            placement='top'
                        >
                            <div
                                className='transparent-background'
                                style={{
                                    width: 25,
                                    height: 25,
                                    color: '#777',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setSelectedArtifact(null)}
                            >
                                <Close size={20} />
                            </div>
                        </Tooltip>
                    }

                </div>
            )}

            {
                <>
                    <div
                        className="resize-handle"
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: 5,
                            height: '100%',
                            cursor: 'ew-resize',
                        }}
                        onMouseDown={(e) => handleMouseDown(e, 'horizontal')}
                    />

                    <div
                        className="resize-handle"
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            height: 5,
                            cursor: 'ns-resize',
                        }}
                        onMouseDown={(e) => handleMouseDown(e, 'vertical')}
                    />

                    {/* <div
                        className="resize-handle"
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            width: 10,
                            height: 10,
                            cursor: 'nwse-resize',
                        }}
                        onMouseDown={(e) => handleMouseDown(e, 'corner')}
                    /> */}
                </>
            }
        </div>
    );
};

export default Artifact;