import FlowEditor from "./FlowEditor"
import logo from '../../logo.png';
import { Selector } from "../common/Selector";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { docVisited, fetchFlowDocs, getAppConfig, getUserInfo, trashDoc, upsertDoc } from "@/actions/ticketAction";
import { getState } from "@/reducers/listReducer";
import { KeyboardReturn } from '@styled-icons/material';
import { APP_CONFIG, FLOW_INPUT_MODAL, FLOW_MODAL, INVITED_CODE } from "@/constants/actionTypes";
import SettingsModal from "../SettingsModal";
import { PromptModal } from "../settings/PromptModal";
import { ImageUploadModal } from "../common/ImageUploadModal";
import { useMediaQuery } from 'react-responsive';
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import InviteFriendsModal from "../feedbacks/InviteFriendsModal";
import GlobalDndContext from "../DndContext";
// import googleOneTap from "google-one-tap";
import { GoogleLogin } from "../../views/pages/login/GoogleLogin";
import { LlmAPIKeyModal } from "../settings/LlmAPIKeyModal";
import { EditableMenuItemActions } from "./EditableMenuItemActions";
import { Menu } from '@styled-icons/material/Menu';
import ReactGA from "react-ga4";
import { MOBILE_MEDIA_QUERY } from "../../utils/constants";
import FlowGuide from "./FlowGuide";
import ConfirmDialog from "../ConfirmDialog";
import ExportModal from "../header/ExportModal";
import { PROMPT_DIALOG } from "../../constants/actionTypes";
import { getPrompt } from "../../actions/ticketAction";
const linkToPage = (doc) => {
    if (doc) {
        return `/aiflow?hid=${doc.hid}`
    }

    return '/aiflow'
}

let lng = navigator.language || navigator.userLanguage

if (lng && lng.indexOf('zh-') > -1) {
    lng = 'cn'
}

if (lng != 'cn') {
    lng = 'en'
}

const BOARD_TITLE_WIDTH = 300;

const AIFlow = () => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const loginUser = useSelector(state => state.loginIn.user);
    const flow_doc_lists = useSelector(state => getState(state.flow_doc_lists, loginUser.workingOrgId));

    const location = useLocation();
    const params = new Proxy(new URLSearchParams(location.search), {
        get: (searchParams, prop) => searchParams.get(prop) || '',
    });

    const { hid, queryType, userInput, action, nodeType, title, content, src, url, promptId } = params;
    const uiLng = useSelector(state => state.uiState.lng) || lng || 'en';
    const inviteCode = useSelector(state => state.uiState.invited_code)
    const [boards, setBoards] = useState([]);
    const [board, setBoard] = useState('');
    const [initNodeData, setInitNodeData] = useState();
    const [itemTrashed, setItemTrashed] = useState();

    const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

    useEffect(() => {
        dispatch(getAppConfig({ service: 'funblocks' }, (data) => {
            dispatch({
                type: APP_CONFIG,
                value: data
            })
        }));
    }, [])

    useEffect(() => {
        // ReactGA.initialize(GOOGLE_ANALYTICS_TRACKID);
        ReactGA.send({ hitType: "pageview", page: "/aiflow", title: "AIFlow" });
    }, [])

    useEffect(() => {
        if (params?.inviteCode) {
            dispatch({
                type: INVITED_CODE,
                value: params.inviteCode
            })
        }
    }, [params?.inviteCode])

    useEffect(() => {
        //Already funblocks user, no reward.
        if (!!loginUser?._id || !params?.inviteCode) {
            return;
        }

        getCurrentBrowserFingerPrint().then((fingerprint) => {
            if (fingerprint == params?.fp) {
                return;
            }

            // 延时 3 秒
            const timer = setTimeout(() => {
                // 将访问者信息发送到服务器
                dispatch(docVisited({
                    hid: params.hid,
                    inviteCode: params.inviteCode,
                    fingerprint
                }, (award) => {
                }))
            }, 3000);

            // 清理延时器
            return () => clearTimeout(timer);

        })
    }, [params?.inviteCode])

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                dispatch(getUserInfo());
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);


    useEffect(() => {
        !!loginUser?._id && dispatch(fetchFlowDocs({ orgId: loginUser.workingOrgId }))
    }, [loginUser])

    useEffect(() => {
        let bs = flow_doc_lists?.items?.map(item => {
            return {
                label: item.title || intl.formatMessage({ id: 'untitled' }),
                value: item.hid,
                rightElement: <EditableMenuItemActions
                    item={item}
                    onDelete={(item) => {
                        dispatch(trashDoc(item, () => setItemTrashed(item.hid), 'AIFlow'))
                    }}
                    onEdit={(item) => {
                        dispatch({
                            type: FLOW_INPUT_MODAL,
                            value: {
                                id: 'board_title',
                                hid: item.hid,
                                value: item.title,
                                visible: true
                            }
                        })
                    }}
                />
            }
        }) || [];

        bs.unshift({
            label: '+ ' + intl.formatMessage({ id: 'new_board' }),
            value: 'new_board'
        });

        setBoards(bs);

        if (bs.length == 1) {
            setBoard('new_board');
        }

    }, [flow_doc_lists?.items, intl])

    useEffect(() => {
        if (!itemTrashed) return;

        if (flow_doc_lists?.items?.length) {
            let currentBoard = [...flow_doc_lists?.items].shift();

            if (currentBoard) {
                history.push(linkToPage(currentBoard))
            }
        }

        setItemTrashed(null);
    }, [itemTrashed])

    useEffect(() => {
        if (hid || loginUser?._id || !uiLng || nodeType || queryType || initNodeData) return;

        const currentUrl = new URL(window.location.href);
        let toHid;

        // if (loginUser?._id) {
        //     toHid = '';
        // } else if (source != 'flow') {
        toHid = 'welcome_to_aiflow_' + uiLng;
        // }

        // if (!toHid) {

        // } else {
        if (currentUrl.hash) {
            const hashParts = currentUrl.hash.split('?');

            const hashSearchParams = new URLSearchParams(hashParts[1] || '');

            if (!hashSearchParams.has('hid')) {
                hashSearchParams.append('hid', toHid);
            }

            currentUrl.hash = hashParts[0] + '?' + hashSearchParams.toString();
        } else {
            currentUrl.hash = '#/aiflow?hid=' + toHid;
        }
        // }

        window.history.replaceState({}, '', currentUrl);
        window.location.reload()
    }, [uiLng])

    const onChangeBoard = useCallback((board) => {
        if (!board) return;

        if (board !== 'new_board') {
            history.push(linkToPage({ hid: board, type: 'flow' }));
        } else if (loginUser?._id) {
            let newdoc = {
                parent: 'root',
                type: 'flow',
            };

            dispatch(upsertDoc({
                data: {
                    space: 'private',
                    orgId: loginUser.workingOrgId,
                    doc: newdoc,
                    isBlank: true
                }
            }, (doc) => {
                history.push(linkToPage(doc));
                setBoard(doc.hid)
            }, 'editor'));
        } else {
            dispatch({
                type: FLOW_MODAL,
                value: {
                    visible: true,
                    action: 'login'
                }
            })
        }
    }, [loginUser])

    useEffect(() => {
        if (!hid && boards?.length < 2 || nodeType || initNodeData) {
            return;
        }

        setBoard(hid || boards[1].value);
    }, [hid, boards])

    useEffect(() => {
        !!board && onChangeBoard(board);
    }, [board])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        if (promptId) {
            dispatch(getPrompt({ _id: promptId }, (item) => {
                dispatch({
                    type: PROMPT_DIALOG,
                    value: {
                        visible: true,
                        data: item,
                        mode: 'share'
                    }
                })
            }))

            searchParams.delete('promptId');
            return;
        }

        if (!action) return;

        let nodeData = {
            userInput: userInput,
            queryType,
            nodeType: nodeType || 'aigc',
            src,
            ai_action: action,
            content,
            title: title || userInput,
            url
        }

        setInitNodeData(nodeData);

        searchParams.delete('userInput');
        searchParams.delete('queryType');
        searchParams.delete('nodeType');
        searchParams.delete('src');
        searchParams.delete('action');
        searchParams.delete('title');
        searchParams.delete('content');
        searchParams.delete('url');

        const newSearch = searchParams.toString();
        const newPath = `${location.pathname}?${newSearch}`;

        history.replace(newPath);
    }, [location])

    return <div style={{ position: 'relative', touchAction: 'none' }}>
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'row',
            borderRight: isMobile ? undefined : '1px solid gray',
            borderBottomRightRadius: 4,
            height: 31,
            // boxShadow: `5px 5px 1px rgba(0, 0, 0, 10%)`,
            boxShadow: '0px 0px 8px #bbb',
            backgroundColor: 'white',
            zIndex: 1001
        }}
        >
            {
                !isMobile &&
                <>
                    <div
                        className="flow-logo"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignSelf: 'center',
                            alignItems: 'center',
                            columnGap: 6,
                            paddingLeft: 14,
                            paddingRight: 14,
                            borderBottom: '1px solid gray',
                            height: '100%',
                        }}>
                        <img width={25} src={logo} alt="Logo" />
                        AI Flow
                    </div>
                    <div style={{
                        height: '100%',
                        width: '0px',
                        borderRight: '1px solid gray',
                        borderBottom: '1px solid gray',
                    }} />
                </>
            }

            {
                boards &&
                <div
                    className="flow-boards"
                    style={{
                        width: isMobile ? undefined : 240,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                    <Selector
                        options={boards}
                        value={board}
                        tooltip={intl.formatMessage({ id: 'choose_flow_board' })}
                        placeholder={intl.formatMessage({ id: 'choose_flow_board' })}
                        onChange={(value) => {
                            setBoard(value)
                        }}
                        inputStyle={isMobile ? {
                            paddingLeft: 4
                        } : {
                            margin: 0,
                            border: 'none',
                            width: '100%',
                            height: '100%',
                            borderBottom: '1px solid gray',
                            borderBottomLeftRadius: 0,
                            paddingLeft: 10,
                            paddingRight: 6,
                            fontSize: 13
                        }}
                        triggerElement={isMobile && <div style={{
                            // borderRadius: 5,
                            // border: '1px solid gray'
                        }}>
                            <Menu color="gray" size={24} />
                        </div>}
                        popoverStyle={{
                            width: BOARD_TITLE_WIDTH
                        }}
                        labelStyle={{
                            fontSize: isMobile ? 14 : 16
                        }}
                        itemStyle={{
                            paddingLeft: 10,
                            paddingRight: 10
                        }}
                        dropdownIconSize={22}
                    />
                </div>
            }
        </div>
        <GlobalDndContext>
            <FlowEditor
                standAlone={true}
                initNodeData={initNodeData}
                onNewBoardCreated={(hid) => setBoard(hid)}
            />
        </GlobalDndContext>
        <SettingsModal />
        <PromptModal />
        <LlmAPIKeyModal />
        <ImageUploadModal isMobile={isMobile} />
        <InviteFriendsModal />
        <ExportModal />
        <PromptModal />
        <ConfirmDialog />
        {
            !(loginUser?._id || window.self !== window.top) &&
            <GoogleLogin showButton={false} inviteCode={inviteCode} />
        }
        <FlowGuide />
    </div>
}

export default AIFlow