import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl'
import enMessages from '../locales/en';
import cnMessages from '../locales/cn';
// import frMessages from '../locales/fr';
// import deMessages from '../locales/de';
// import esMessages from '../locales/es';
// import ptMessages from '../locales/pt';
// import jaMessages from '../locales/ja';

let locale = navigator.language;

if (locale && locale.indexOf('zh-') > -1) {
  locale = 'cn';
}

if (!['cn',
  // , 'fr', 'de', 'es', 'pt', 'ja'     //default set to En, due to not confident to the AI translated i18n file
].includes(locale)) {
  locale = 'en';
}

const messages = locale === 'cn' && cnMessages
  // || locale === 'fr' && frMessages
  // || locale === 'de' && deMessages
  // || locale === 'es' && esMessages
  // || locale === 'pt' && ptMessages
  // || locale === 'ja' && jaMessages
  || enMessages;

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache()

const intl = createIntl({
  locale: locale,
  messages,
}, cache);


export const getLocale = () => {
  return locale;
}


export default intl;